<template>
  <div class="Industrial">
    <van-popup
      v-model="this.$store.state.show"
      position="right"
      :style="{ height: '100%', width: '223px' }"
      class="genduomenu"
    >
      <el-input
        suffix-icon="el-icon-search"
        class="PeSearch"
        v-model="searchText"
      >
      </el-input>
      <ul class="peMenu">
        <li @click="toHome">
          <span>首页</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toBusiness">
          <span>经营范围</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toNews">
          <span>新闻资讯</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toAdvantage">
          <span>核心优势</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toGroup_Introduction">
          <span>集团介绍</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toContact_us">
          <span>联系我们</span><i class="el-icon-arrow-right"></i>
        </li>
      </ul>
    </van-popup>
    <BusinessHeader></BusinessHeader>
    <div class="layer1" @click="popHide">
      <div class="titles">
        <h2>
          {{ $t('Industrial.IndustrialControlBoard') }}<span>/</span>
          <span>{{ $t('Industrial.IndustrialControlBoardE') }}</span>
        </h2>
      </div>
      <el-divider></el-divider>
      <p class="newsTitle">
        {{ $t('Industrial.NewsTitle') }}
      </p>
      <p>
        {{ $t('Industrial.Content1') }}
      </p>
      <img src="../../assets/Business/270.png" alt="" />
      <img src="../../assets/Business/267.png" alt="" />
      <img src="../../assets/Business/264.png" alt="" />
    </div>
    <div class="layer2">
      <h2>{{ $t('Industrial.BusinessCapability') }}</h2>
      <p>{{ $t('Industrial.CooperativePartner') }}</p>
      <div class="nav">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </div>
      <div class="plz">
        {{ $t('Industrial.Plz')
        }}<span @click="toContact_us"> {{ $t('Industrial.ContactUs') }}</span>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import BusinessHeader from '../../components/BusinessHeader.vue'
import footers from '../../components/footer.vue'

export default {
  components: {
    BusinessHeader,
    footers
  },
  data () {
    return {
      searchText: ''
    }
  },
  created () {
    this.$store.commit('setShow', false)
  },
  methods: {
    toHome () {
      this.$router.push('/Home')
    },
    toBusiness () {
      this.$router.push('/Business')
    },
    toNews () {
      this.$router.push('/News')
    },
    toAdvantage () {
      this.$router.push('/Advantage')
    },
    toGroup_Introduction () {
      this.$router.push('/Group_Introduction')
    },
    toContact_us () {
      this.$router.push('/Contact_us')
    },
    popHide () {
      this.$store.commit('setShow', false)
    }
  }
}
</script>

<style lang="less" scoped>
.Industrial {
  #header {
    width: 100%;
    height: 960px;
    background: url('../../assets/Home/458.png') no-repeat;
    background-size: cover;
    position: relative;
    .top {
      width: 1400px;
      height: 80px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      .logo {
        width: 95px;
        height: 27px;
        margin-right: 30px;
      }
      .left {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          color: #fff;
          list-style: none;
          display: block;
          cursor: pointer;
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          .homeIcon {
            margin-right: 10px;
          }
        }
        .first {
          border-left: 1px solid #fff;
          padding-left: 21px;
          display: flex;
          align-items: center;
        }
      }
      .right {
        margin-left: 145px;
        width: 280px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-input {
          width: 206px;
          height: 36px;
          .el-input__inner {
            background-color: rgba(255, 255, 255, 0.3);
            -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
            border-radius: 25px;
            border: 0;
            color: #fff;
          }
        }
      }
    }
  }
  .layer1 {
    margin: 90px 140px;
    .titles {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      line-height: 80px;
      padding-bottom: 60px;
      h2 {
        display: block;
        width: 760px;
        font-size: 40px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        display: flex;
      }
      span {
        font-size: 16px;
        font-family: Didot, Didot-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        &:nth-child(1) {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
    .el-divider {
      margin-bottom: 90px;
    }
    .newsTitle {
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: left;
      color: #161616;
      line-height: 48px;
    }
    p {
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #161616;
      line-height: 48px;
      margin-bottom: 90px;
    }
    img {
      display: block;
      width: 100%;
      height: 720px;
      margin-bottom: 30px;
    }
  }
  .layer2 {
    h2 {
      font-size: 40px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 700;
      text-align: left;
      color: #333333;
      text-align: center;
    }
    p {
      font-size: 24px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      color: #707070;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    width: 1640px;
    margin: 0 auto;
    .nav {
      display: flex;
      flex-wrap: wrap;
      height: 810px;
      li {
        list-style: none;
        width: 33.3333%;
        &:nth-child(1) {
          width: 100%;
          background: url('../../assets/Business/367.png') no-repeat;
          border-bottom: 1px dashed #969798;
        }
        &:nth-child(2) {
          background: url('../../assets/Business/368.png') no-repeat;
        }
        &:nth-child(3) {
          background: url('../../assets/Business/369.png') no-repeat;
          border-left: 1px dashed #969798;
        }
        &:nth-child(4) {
          background: url('../../assets/Business/370.png') no-repeat;
          border-left: 1px dashed #969798;
        }
      }
    }
    .plz {
      font-size: 40px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 700;
      text-align: center;
      color: #333333;
      line-height: 84px;
      margin-top: 90px;
      span {
        color: #4090e7;
      }
    }
  }
  .footer {
    background: url('../../assets/Home/bottom.png');
    background-size: cover;
    height: 470px;
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .top {
      width: 1400px;
      height: 40px;
      margin: 0 auto;
      padding-top: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        height: 41px;
      }
      .right {
        display: flex;
        justify-content: space-between;
        li {
          margin-left: 100px;
          display: flex;
          align-items: center;
        }
      }
    }
    .bottom {
      width: 1400px;
      height: 32px;
      margin: 0 auto;
      margin-top: 92px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .first {
        display: flex;
        align-items: center;
        .left {
          margin-right: 10px;
        }
      }
      .last {
        display: flex;
        align-items: center;
        * {
          margin-left: 30px;
        }
        span {
          font-size: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .Industrial {
    .layer1 {
      margin: 0;
      padding-left: 20px;
      padding-right: 20px;
      .peHied {
        display: none;
      }
      .titles {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-bottom: 0px;
        align-items: center;

        h2 {
          display: block;
          width: 360px;
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
          display: flex;
          align-items: center;
        }
        span {
          font-size: 12px;
          font-family: Didot, Didot-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          &:nth-child(1) {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
      .el-divider {
        margin-bottom: 10px;
        margin-top: 10px;
      }
      .newsTitle {
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: left;
        color: #161616;
        line-height: 24px;
      }
      p {
        font-size: 10px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #161616;
        line-height: 24px;
        margin-bottom: 20px;
      }
      img {
        display: block;
        width: 100%;
        height: 219.5px;
        margin-bottom: 15px;
      }
    }
    .layer2 {
      h2 {
        font-size: 15px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        text-align: center;
      }
      p {
        font-size: 10px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        color: #707070;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0 auto;
      .nav {
        display: flex;
        flex-wrap: wrap;
        height: 405px;
        li {
          list-style: none;
          width: 33.3333%;
          &:nth-child(1) {
            width: 100%;
            height: 202.5px;
            background: url('../../assets/PE/NewEnergy/472.png') no-repeat;
            background-size: cover;
            border-bottom: 1px dashed #969798;
          }
          &:nth-child(2) {
            background: url('../../assets/PE/NewEnergy/475.png') no-repeat;
            background-size: cover;
            height: 202.5px;
          }
          &:nth-child(3) {
            background: url('../../assets/PE/NewEnergy/473.png') no-repeat;
            background-size: cover;
            border-left: 1px dashed #969798;
            height: 202.5px;
          }
          &:nth-child(4) {
            background: url('../../assets/PE/NewEnergy/488.png') no-repeat;
            background-size: cover;
            border-left: 1px dashed #969798;
            height: 202.5px;
          }
        }
      }
      .plz {
        font-size: 15px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: center;
        color: #333333;
        line-height: 24px;
        margin-top: 20px;
        span {
          color: #4090e7;
        }
      }
    }
    .footer {
      background: url('../../assets/Home/bottom.png');
      background-size: cover;
      height: 470px;
      margin-top: 90px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .top {
        width: 1400px;
        height: 40px;
        margin: 0 auto;
        padding-top: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          height: 41px;
        }
        .right {
          display: flex;
          justify-content: space-between;
          li {
            margin-left: 100px;
            display: flex;
            align-items: center;
          }
        }
      }
      .bottom {
        width: 1400px;
        height: 32px;
        margin: 0 auto;
        margin-top: 92px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .first {
          display: flex;
          align-items: center;
          .left {
            margin-right: 10px;
          }
        }
        .last {
          display: flex;
          align-items: center;
          * {
            margin-left: 30px;
          }
          span {
            font-size: 20px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
